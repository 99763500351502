import { _superadminNav, _employeeNav, _setupadminNav, _moduleadminNav } from './../containers/_nav'


export const superadmin = 'superadmin'

export const employee = 'employee'

export const setupadmin = 'setupadmin'

export const moduleadmin = 'moduleadmin'




export const superadminFolder = '/super_admin'

export const superadminUrlprefix = '/superadmin'




export const employeeFolder = '/employee'

export const employeeUrlprefix = '/employee'


export const moduleAdminFolder = `/module_admin`




export const userRoles = {

    "Super Admin": { role: superadmin, to_show: 'Super Admin', nav: _superadminNav, urlPrefix: superadminUrlprefix, folderPath: superadminFolder, loginUrl: '/superadmin' },

    "Employee": { role: employee, to_show: 'Employee', nav: _employeeNav, urlPrefix: employeeUrlprefix, folderPath: employeeFolder, loginUrl: '/employee' },

    "Setup Admin": { role: setupadmin, to_show: 'Setup Admin', nav: _setupadminNav, urlPrefix: superadminUrlprefix, folderPath: superadminFolder, loginUrl: '/superadmin' },

    "Module Admin": { role: moduleadmin, to_show: 'Module Admin', nav: _moduleadminNav, urlPrefix: employeeUrlprefix, folderPath: moduleAdminFolder, loginUrl: '/employee' },

}

export const countPerPage = 10;
export const countPerPage5 = 5; 
export const countryCode = '+91'

export const fileSize = { label: 500, value: 500000 };

export const VERSION = '0.1.1'
