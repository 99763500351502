import CIcon from '@coreui/icons-react'
import { WorkReportIcon } from 'src/assets/svg_icons';

export const _moduleadminNav = {
    dashboardNav: [
        {
            _tag: 'CSidebarNavItem',
            name: 'Dashboard',
            to: `/dashboard`,
            icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
            exact: false,
        },
    ],
    _moduleadminNav: [
        {
            _tag: 'CSidebarNavDropdown',
            name: 'Manage Module',
            route: '/base',
            icon: 'cil-people',
            _children: [
                // {
                //     _tag: 'CSidebarNavItem',
                //     icon: <CIcon name='cil-building' className='mr-2' customClasses="c-sidebar-nav-icon" />,
                //     name: 'DWR',
                //     to: '/base/tooltips',
                //     code: 'dwr',
                //     exact: false,
                // },
                {
                    _tag: 'CSidebarNavItem',
                    icon: <CIcon name='cil-calendar' className='mr-2' />,
                    name: 'Attendance',
                    to: '/attendance-module',
                    code: "attendance",
                    exact: false,
                },
                {
                    _tag: 'CSidebarNavItem',
                    icon: <CIcon name='cil-clipboard' className='mr-2' />,
                    name: 'Assets',
                    to: '/assets',
                    code: 'assets',
                    exact: false,
                },
                {
                    _tag: 'CSidebarNavDropdown',
                    icon: <CIcon name='cil-building' className='mr-2' />,
                    name: 'Core HR',
                    route: '/core-hr',
                    code: 'core_hr',
                    // icon: 'cil-people',
                    _children: [
                        {
                            _tag: 'CSidebarNavItem',
                            icon: <CIcon name='cil-user-plus' className="ml-3 mr-2" />,
                            name: 'Onboarding',
                            to: '/employee/add',
                            exact: false,
                        },
                        {
                            icon: <CIcon name='cil-building' className='ml-3 mr-2' />,
                            _tag: 'CSidebarNavItem',
                            name: 'Manage Employees',
                            to: '/employee',
                            exact: false,
                        },
                        {
                            icon: <CIcon name='cil-building' className='ml-3 mr-2' />,
                            _tag: 'CSidebarNavItem',
                            // name: 'Document & Template',
                            name: 'HR Template',
                            to: '/document/template',
                            exact: false,
                        },
                        {
                            icon: <CIcon name='cil-building' className='ml-3 mr-2' />,
                            _tag: 'CSidebarNavItem',
                            // name: 'Document & Template',
                            name: 'HR Docs',
                            to: '/hr-docs',
                            exact: false,
                        },
                    ],
                },
                {
                    _tag: 'CSidebarNavDropdown',
                    name: 'Cash Voucher',
                    route: '/cash-voucher',
                    code: 'global_vouchers',
                    icon: <CIcon name='cil-building' className='mr-2' />,
                    _children: [
                        {
                            _tag: 'CSidebarNavItem',
                            name: 'Dashboard',
                            icon: <CIcon name='cil-user-plus' className="ml-3 mr-2" />,
                            to: '/cash-voucher',
                            exact: false,
                        },
                        {
                            _tag: 'CSidebarNavItem',
                            icon: <CIcon name='cil-building' className='ml-3 mr-2' />,
                            name: 'Statements',
                            to: '/cash-voucher/statement',
                            exact: false,
                        },
                    ],
                },
                {
                    _tag: 'CSidebarNavItem',
                    icon: < CIcon name='cil-building' className='mr-2' />,
                    name: 'Content Management',
                    to: '/base/tooltips',
                    code: 'content_management',
                    exact: false,
                },
                {
                    _tag: 'CSidebarNavItem',
                    icon: <WorkReportIcon height="20" width="18" className='mr-2' customClasses="c-sidebar-nav-icon" />,
                    name: 'Daily Work Report',
                    to: '/admin-dwr',
                    code: "dwr",
                    exact: false,
                },
                {
                    _tag: 'CSidebarNavItem',
                    icon: <CIcon name='cil-cash' className='mr-2' />,
                    name: 'Expense',
                    to: '/expense-module',
                    code: 'expense',
                    exact: false,
                },
                {
                    _tag: 'CSidebarNavItem',
                    icon: <CIcon name='cil-cash' className='mr-2' />,
                    name: 'Expense',
                    to: '/expense-module',
                    code: "approved_expense",
                    exact: false,
                },
                {
                    _tag: 'CSidebarNavItem',
                    icon: <CIcon name='cil-cash' className='mr-2' />,
                    name: 'Expense',
                    to: '/expense-module',
                    code: "settled_expense",
                    exact: false,
                },
                {
                    _tag: 'CSidebarNavItem',
                    icon: <CIcon name='cil-calendar' className='mr-2' />,
                    name: 'Leaves',
                    to: '/leaves-module',
                    code: "leave",
                    exact: false,
                },
                {
                    _tag: 'CSidebarNavItem',
                    icon: <CIcon name='cil-clipboard' className='mr-2' />,
                    name: 'Notice Board',
                    to: '/notice-board',
                    code: 'notice_board',
                    exact: false,
                },
                {
                    _tag: 'CSidebarNavItem',
                    name: 'News Admin',
                    to: `/news`,
                    code: 'news',
                    icon: <CIcon name="cil-speedometer" className='mr-2' />,
                    exact: false,
                },
                {
                    _tag: 'CSidebarNavDropdown',
                    icon: <CIcon name='cil-building' className='mr-2' />,
                    name: 'Policy',
                    route: '/policy',
                    code: 'policy',
                    // icon: 'cil-people',
                    _children: [
                        {
                            _tag: 'CSidebarNavItem',
                            icon: <CIcon name='cil-user-plus' className="ml-3 mr-2" />,
                            name: 'Attendance Policy',
                            to: '/attendance-policy',
                            exact: false,
                        },
                        {
                            icon: <CIcon name='cil-building' className='ml-3 mr-2' />,
                            _tag: 'CSidebarNavItem',
                            name: 'Holiday Policy',
                            to: '/holiday-policy',
                            exact: false,
                        },
                        {
                            icon: <CIcon name='cil-building' className='ml-3 mr-2' />,
                            _tag: 'CSidebarNavItem',
                            name: 'Leave Policy',
                            to: '/leaves-policy',
                            exact: false,
                        },
                        {
                            icon: <CIcon name='cil-building' className='ml-3 mr-2' />,
                            _tag: 'CSidebarNavItem',
                            name: 'Weekly-off Policy',
                            to: '/weeklyoff-policy',
                            exact: false,
                        },
                        // {
                        //     icon: <CIcon name='cil-building' className='ml-3 mr-2' />,
                        //     _tag: 'CSidebarNavItem',
                        //     name: 'Expense Policy',
                        //     to: '/employee',
                        //     exact: false,
                        // },
                        {
                            icon: <CIcon name='cil-building' className='ml-3 mr-2' />,
                            _tag: 'CSidebarNavItem',
                            name: 'Policy Mapping',
                            to: '/policy-mapping',
                            exact: false,
                        },
                    ],
                },
                {
                    _tag: 'CSidebarNavDropdown',
                    name: 'Operations Manager',
                    route: '/cash-voucher',
                    code: 'operations',
                    icon: <CIcon name='cil-building' className='mr-2' />,
                    _children: [
                        {
                            _tag: 'CSidebarNavItem',
                            name: 'Products',
                            icon: <CIcon name='cil-building' className='mr-2' />,
                            to: '/products',
                            exact: false,
                        },
                        {
                            _tag: 'CSidebarNavItem',
                            name: 'Warehouse',
                            icon: <CIcon name='cil-building' className='mr-2' />,
                            to: '/warehouse',
                            exact: false,
                        },
                    ],
                },

            ],
        },
    ],
};